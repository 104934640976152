import axios from "axios";
import qs from "qs";
import authApi from "./auth";
import { LOCAL_STORAGE_KEY } from "constant/enums";
import { handleHardLogout } from "utils/common";

const getToken = () =>
  localStorage.getItem(LOCAL_STORAGE_KEY.ACCESS_TOKEN)
    ? localStorage.getItem(LOCAL_STORAGE_KEY.ACCESS_TOKEN)
    : "";

const getAuthorizationHeader = () => `Bearer ${getToken()}`;

const request = axios.create({
  baseURL: "http://service4all.vinorsoft.com:18003/api/v1",
  headers: {
    "content-type": "application/json",
    Authorization: getAuthorizationHeader(),
  },
  paramsSerializer: (params) => qs.stringify(params),
});

request.interceptors.request.use(function (config) {
  if (config.headers) {
    config.headers["Authorization"] = getAuthorizationHeader();
  }
  return config;
});

// SBA axios client
const SBAAxiosClient = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL,
  headers: {
    "content-type": "application/json",
    Authorization: getAuthorizationHeader(),
  },
  timeout: 60000,
  paramsSerializer: (params) => qs.stringify(params),
});

SBAAxiosClient.interceptors.request.use(function (config) {
  if (config.headers) {
    config.headers["Authorization"] = getAuthorizationHeader();
  }
  return config;
});

SBAAxiosClient.interceptors.response.use(
  (response) => {
    if (response && response.data) {
      return response;
    }
    return response;
  },
  async function (error) {
    // console.log("ggg: ", error);
    if (error.response.status === 404) {
      return error.response.status;
    }
    const originalRequest = error.config;
    if (
      // (error.code === "ERR_NETWORK" ||
      //   (error.code === "ERR_BAD_REQUEST" && error.response.status === 401)) &&
      error.code === "ERR_BAD_REQUEST" &&
      error.response.status === 401 &&
      !originalRequest._retry
    ) {
      originalRequest._retry = false;
      const response = await authApi.refesh();
      if (response.data.code === 200 || response.data.code === 400) {
        return;
      }
      if (response.data.code === 403) {
        handleHardLogout();
        return;
      }

      localStorage.setItem(
        LOCAL_STORAGE_KEY.ACCESS_TOKEN,
        response.data.refreshToken
      );
      SBAAxiosClient.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${response.data.refreshToken}`;
      originalRequest.headers = {
        Authorization: `Bearer ${response.data.refreshToken}`,
      };
      return axios(originalRequest);
    }
    return Promise.reject(error);
  }
);

export { request, SBAAxiosClient };
